import { useTranslation } from 'next-i18next'
import { CategoriesMenu } from 'web_core/assets/category/Menu'

type Props = {
  width: number
  top: number
  categoriesMenu: boolean
  activeCategoryId: string
}

export const LeftSidebar = ({
  width,
  top,
  categoriesMenu,
  activeCategoryId,
}: Props) => {
  const { i18n } = useTranslation()
  return (
    <>
      <div className="flex-shrink-0 d-md-flex d-none" style={{ width }} />
      <div
        className="overflow-hidden d-md-flex d-none flex-column sidenav bottom-0 position-fixed shadow" // 無限スクロール時もメニューを画面上で固定させるために position-fixed を使用
        style={{ width, top }}
      >
        {categoriesMenu && (
          <CategoriesMenu
            locale={i18n.language}
            activeCategoryId={activeCategoryId}
          />
        )}
      </div>
    </>
  )
}
