import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { Card } from 'react-bootstrap'
import { Category } from '../../firebase'
import { Designed } from '../../types'

type Props = {
  category: Category
}

export const CategoryCard = ({ category }: Designed<Props>) => {
  const { i18n } = useTranslation()
  return (
    <Card>
      <Card.Header>{category.localized_name[i18n.language]}</Card.Header>
      <Card.Body>
        <p className="mb-2 mt-0">{category.localized_summary[i18n.language]}</p>
        <Link href={`/categories/${category.id}`}>
          <a>詳細を見る</a>
        </Link>
      </Card.Body>
    </Card>
  )
}
