import { ESTextbook } from '../../elastic_search'
import { Textbook } from '../../firebase'
import { Designed, PromiseVoid } from '../../types'

type Props = {
  textbook: Textbook | ESTextbook
  onClick?: () => PromiseVoid
  width: number | string
}

export const TextbookImage = ({
  textbook,
  width,
  onClick: handleClick,
  className,
  style,
}: Designed<Props>) => {
  const height = (Number(width) / 3) * 4
  return (
    <div
      className={className}
      onClick={handleClick}
      style={{ width: width, height: height, ...style }}
    >
      <img
        className="m-0 w-100 h-100"
        src={'/default_textbook_thumbnail.png'}
        alt={`${textbook.title}の表紙`}
      />
    </div>
  )
}
