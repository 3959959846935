import { Stack } from 'react-bootstrap'
import { LinkWrapper } from '../../assets/LinkWrapper'
import { CategoryBadge } from '../../assets/category'
import { ReferencesList } from '../../assets/reference'
import { TextbookImage, TextbookOperationLinks } from '../../assets/textbook'
import {
  Category,
  Chapter,
  Macro,
  Page,
  Reference,
  Textbook,
} from '../../firebase'
import { Designed } from '../../types'
import { MathJax } from '../mathdown/mathjax'

type Props = {
  textbook: Textbook
  macros: Macro[]
  pages: Page[]
  chapters: Chapter[]
  category: Category
  references: Reference[]
}

export const TextbookDetail = ({
  textbook,
  pages,
  macros,
  chapters,
  category,
  references,
}: Designed<Props>) => (
  <div>
    <div className="d-block d-sm-flex justify-content-between">
      <div className="align-self-center mb-4">
        <Stack direction="horizontal" className="mb-2" gap={1}>
          <CategoryBadge category={category} />
        </Stack>
        <h1 className="h3 text-break mb-1">{textbook.title}</h1>
        <TextbookOperationLinks textbook={textbook} />
      </div>
      <TextbookImage
        textbook={textbook}
        width={150}
        className="align-self-center"
      />
    </div>
    <h2 className="fs-5 fw-bold mt-6">概要</h2>
    <MathJax>
      <p>{textbook.description}</p>
    </MathJax>
    <h2 className="fs-5 fw-bold mt-7">目次</h2>
    <ol>
      {chapters.map((chapter) => {
        const filteredPages = pages.filter((p) => p.chapter_id === chapter.id)
        return (
          <li key={chapter.id}>
            <h3 className="fs-6 fw-bold">{chapter.title}</h3>
            <MathJax macros={macros}>
              <p>{chapter.summary}</p>
            </MathJax>
            {filteredPages.length > 0 && (
              <ol>
                {filteredPages.map((page) => (
                  <li className="mb-2" key={page.id}>
                    <LinkWrapper
                      href={`/textbooks/${textbook.id}/${page.id}`}
                      passHref
                    >
                      <span className="cursor-pointer text-primary">
                        {page.title}
                      </span>
                    </LinkWrapper>
                  </li>
                ))}
              </ol>
            )}
          </li>
        )
      })}
    </ol>
    {references.length > 0 && (
      <>
        <h2 className="fs-5 fw-bold mt-7">参考文献</h2>
        <ReferencesList
          references={references}
          className="gap-1 d-flex flex-column"
          amazonBookImage
          link
        />
      </>
    )}
  </div>
)

export default Page
