import { TextbookImage, TextbookTitleLink } from '.'
import { LinkWrapper } from '../../assets/LinkWrapper'
import { Textbook } from '../../firebase'
import { Designed } from '../../types'

type Props = {
  width: string | number
  textbook: Textbook
  gap?: number
}

export const TextbookImageWithTitle = ({
  width,
  textbook,
  gap = 2,
  ...wrapperProps
}: Designed<Props>) => {
  return (
    <div {...wrapperProps}>
      <div className={`d-flex align-items-center gap-${gap}`}>
        <LinkWrapper href={`/books/${textbook.id}`} passHref>
          <TextbookImage
            textbook={textbook}
            width={width}
            className="flex-shrink-0"
          />
        </LinkWrapper>
        <div className="flex-grow-1 overflow-hidden">
          <TextbookTitleLink
            textbook={textbook}
            className="text-break text-truncate-3"
          />
        </div>
      </div>
    </div>
  )
}
