import Link from 'next/link'
import { TermWithGlossaryInfo } from '../../firebase'
import { Designed } from '../../types'

type Props = {
  terms: TermWithGlossaryInfo[]
}

export const TermsList = ({ terms, ...otherProps }: Designed<Props>) => {
  return (
    <div {...otherProps}>
      {terms.map((term, index) => {
        const prevFirstLetter = index > 0 ? terms[index - 1].reading[0] : null
        const currentFirstLetter = term.reading[0]
        return (
          <div key={index}>
            {prevFirstLetter?.toUpperCase() !==
              currentFirstLetter.toUpperCase() && (
              <h2 className="mt-4">{currentFirstLetter}</h2>
            )}
            <Link href={`/glossaries/${term.glossary_id}`} passHref>
              <a>{term.display}</a>
            </Link>
          </div>
        )
      })}
    </div>
  )
}
