import { useTranslation } from 'next-i18next'
import { MathJax } from '../../assets/mathdown/mathjax'
import { TermsList } from '../../assets/term/List'
import { Category, TermWithGlossaryInfo, Textbook } from '../../firebase'
import { Designed } from '../../types'
import { TextbookList } from '../textbook'

type Props = {
  textbooks: Textbook[]
  category: Category
  terms: TermWithGlossaryInfo[]
}

export const MathpediaCategoryDetail = ({
  category,
  terms,
  textbooks,
  ...otherProps
}: Designed<Props>) => {
  const { i18n } = useTranslation()
  const lang = i18n.language
  return (
    <div {...otherProps}>
      <div className="mathdown-monotone">
        <h1>{category.localized_name[lang]}</h1>
        <MathJax>
          <p>{category.localized_summary[lang]}</p>
        </MathJax>
        {textbooks.length > 0 && (
          <>
            <h2>参考書</h2>
            <TextbookList textbooks={textbooks} />
          </>
        )}
        {terms.length > 0 && (
          <>
            <h2>用語一覧</h2>
            <TermsList terms={terms} />
          </>
        )}
      </div>
    </div>
  )
}
