import { useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'
import { Head, HeadProps } from 'web_core/assets/Head'
import { MobileCategoriesMenu } from 'web_core/assets/category'
import { PopupMessageToast } from 'web_core/assets/messenger/components/PopupMessageToast'
import { useRequireConfirmation } from 'web_core/firebase/hooks/useRequireConfirmation'
import { useBrowser, useMedia, useOS } from 'web_core/hooks'
import { deepMerge } from 'web_core/utils'
import { useLayoutProperty } from '~/context/layout_property'
import { Header } from './Header'
import { LeftSidebar } from './LeftSidebar'
import { RightSidebar } from './RightSidebar'

const lastMonthDate = new Date()
lastMonthDate.setMonth(lastMonthDate.getMonth() - 1)

type Props = {
  head: HeadProps
  /** ページ全体の横幅の最大値 */
  maxWidth?: number | 'none'
  /** ヘッダーの設定 */
  header?: {
    /** PCのヘッダー */
    pc?: {
      show?: boolean
      /** overflowがfalseの場合はfalseにしてもtrueと同じ動作になる */
      fixed?: boolean
    }
    /** モバイルのヘッダー */
    sp?: {
      show?: boolean
      /** overflowがfalseの場合はfalseにしてもtrueと同じ動作になる */
      fixed?: boolean
    }
  }
  /** 左側のサイドバーを表示するかどうか */
  leftSidebar?: boolean
  /** 右側のサイドバーを表示するかどうか */
  rightSideBar?: boolean
  /** カテゴリメニューの設定 */
  categoriesMenu?: {
    /** PCでカテゴリメニューを表示する（trueにしてもleftSidebarがfalseだと表示されない） */
    pc?: boolean
    /** モバイルでカテゴリメニューを表示する */
    sp?: boolean
    /** モバイルでカテゴリメニューを固定する */
    spFixed?: boolean
    /** 現在アクティブなカテゴリのID */
    activeId?: string
  }
  /** ページ全体のスクロールをできるようにするか */
  overflow?: {
    pc?: boolean
    sp?: boolean
  }
  /** コンテンツの周囲に余白をとる場合はtrue */
  padding?: {
    sp: boolean
    pc: boolean
  }

  /** メールを確認していない場合に確認メールの再送信ページに飛ばさない */
  pendingConfirmationRedirect?: boolean
  children: React.ReactNode
}

const defaultProps = {
  maxWidth: 'none',
  /** ヘッダーの設定 */
  header: {
    /** PCでヘッダーのヘッダー */
    pc: {
      show: true,
      /** overflowがfalseの場合はfalseにしてもtrueと同じ動作になる */
      fixed: true,
    },
    /** モバイルのヘッダー */
    sp: {
      show: true,
      /** overflowがfalseの場合はfalseにしてもtrueと同じ動作になる */
      fixed: true,
    },
  },
  /** 左側のサイドバーを表示するかどうか */
  leftSidebar: false,
  /** 右側のサイドバーを表示するかどうか */
  rightSideBar: false,
  /** カテゴリメニューの設定 */
  categoriesMenu: {
    /** PCでカテゴリメニューを表示する（trueにしてもleftSidebarがfalseだと表示されない） */
    pc: false,
    /** モバイルでカテゴリメニューを表示する */
    sp: false,
    /** モバイルでカテゴリメニューを固定する */
    spFixed: false,
    /** 現在アクティブなカテゴリのID */
    activeId: undefined,
  },
  /** ページ全体のスクロールをできるようにするか */
  overflow: {
    pc: true,
    sp: true,
  },
  /** コンテンツの周囲に余白をとる場合はtrue */
  padding: { sp: true, pc: true },

  /** メールを確認していない場合に確認メールの再送信ページに飛ばさない */
  pendingConfirmationRedirect: false,

  head: {
    keywords: ['現代数学', '大学数学', 'Mathpedia'],
    description:
      'Mathpediaは、大学レベル以上の数学を中心に、現代数学を整理する事を目的にしたプロジェクトです。Mathpediaでは、現代数学を整理し見通しを良くする、数学を学ぶ方々を経済的に支援する、助け合える数学コミュニティを作るを大事に運営をしております。',
  },
}

export const DefaultLayout = (props: Props) => {
  const mergedProps = deepMerge(defaultProps, props)
  const { i18n } = useTranslation()
  useRequireConfirmation(mergedProps.pendingConfirmationRedirect)

  const { headerHeight, setMobileCategoriesMenuHeight } = useLayoutProperty()

  const isMd = useMedia('md', true)

  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false)
  useEffect(() => {
    if (isMd && isOffcanvasOpen) setIsOffcanvasOpen(false)
  }, [isMd])

  const browser = useBrowser()
  const os = useOS()

  return (
    <>
      {/* overflow オプションに基づいてスクロール可/不可を適用する処理①  */}
      <div
        className={`${browser ?? ''} ${os ?? ''} ${
          isMd
            ? mergedProps.overflow.pc
              ? ''
              : 'dvh-100 overflow-hidden d-flex flex-column'
            : mergedProps.overflow.sp
            ? ''
            : 'dvh-100 overflow-hidden d-flex flex-column'
        }`}
      >
        <Head {...mergedProps.head} />
        {(isMd ? mergedProps.header.pc.show : mergedProps.header.sp.show) && (
          <Header
            fixed={
              isMd
                ? !mergedProps.overflow.pc || mergedProps.header.pc.fixed
                : !mergedProps.overflow.sp || mergedProps.header.sp.fixed
            }
            maxWidth={mergedProps.maxWidth}
          />
        )}

        <PopupMessageToast top={headerHeight} maxWidth={mergedProps.maxWidth} />
        {mergedProps.categoriesMenu.sp && (
          <MobileCategoriesMenu
            onChangeHeight={setMobileCategoriesMenuHeight}
            top={headerHeight}
            locale={i18n.language}
            fixed={mergedProps.categoriesMenu.spFixed}
            activeCategoryId={mergedProps.categoriesMenu.activeId}
          />
        )}
        {/* overflow オプションに基づいてスクロール可/不可を適用する処理②  */}
        <div
          className={`flex-grow-1 bg-primary-light d-flex gap-lg-4 gap-2 w-100 mx-auto ${
            isMd
              ? mergedProps.overflow.pc
                ? ''
                : 'overflow-hidden'
              : mergedProps.overflow.sp
              ? ''
              : 'overflow-hidden'
          }`}
          style={{ maxWidth: mergedProps.maxWidth }}
        >
          {mergedProps.leftSidebar && (
            <LeftSidebar
              width={200}
              top={headerHeight}
              categoriesMenu={isMd ? mergedProps.categoriesMenu.pc : false}
              activeCategoryId={mergedProps.categoriesMenu.activeId ?? ''}
            />
          )}
          <div
            className={`flex-grow-1 h-100 mw-100 ${
              isMd
                ? mergedProps.overflow.pc
                  ? ''
                  : 'overflow-y-hidden'
                : mergedProps.overflow.sp
                ? ''
                : 'overflow-y-hidden'
            } ${
              isMd
                ? mergedProps.padding.pc
                  ? 'px-4 py-3'
                  : ''
                : mergedProps.padding.sp
                ? 'py-2 py-sm-3 px-2'
                : ''
            }`}
          >
            {mergedProps.children}
          </div>
          {mergedProps.rightSideBar && <RightSidebar />}
        </div>
      </div>
    </>
  )
}
