import Image from 'next/image'
import Link from 'next/link'
import { TwitterTimelineEmbed } from 'react-twitter-embed'

export const RightSidebar = () => {
  return (
    <div
      className="flex-shrink-0 d-none d-print-none py-3 d-xl-flex flex-column gap-6 pe-4"
      style={{ width: 320 }}
    >
      <Link href="/support" passHref>
        <a>
          <Image
            src="/support_banner.png"
            alt="Mathpediaを支援する"
            width={1370}
            height={1276}
          />
        </a>
      </Link>
      <div>
        <h3 className="fs-6 fw-bold">運営者のTwitter</h3>
        <div className="w-100" style={{ minHeight: 500 }}>
          <TwitterTimelineEmbed
            sourceType="profile"
            screenName="Mathpedia_tutor"
            options={{ height: 500 }}
          />
        </div>
      </div>
      <div>
        <h3 className="fs-6 fw-bold mb-2">技術提供および開発</h3>
        <div style={{ width: 200 }}>
          <Link href="https://mathlog.info" passHref>
            <a>
              <Image
                src="/mathlog.png"
                alt="技術提供・開発 Mathlog"
                width={890}
                height={218}
              />
            </a>
          </Link>
        </div>
      </div>
    </div>
  )
}
