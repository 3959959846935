import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { SearchField } from 'web_core/assets/SearchField'
import { useAuthState } from 'web_core/firebase/hooks'
import { useElementSize, useMedia } from 'web_core/hooks'
import { useLayoutProperty } from '~/context/layout_property'
import { PCHeaderUserMenu } from './PCHeaderUserMenu'

type Props = {
  fixed: boolean // ヘッダーを上部に固定する
  maxWidth: number | 'none' // ページ全体の横幅の最大値 ページ全体で合わせる
}

export const Header = ({ fixed, maxWidth }: Props) => {
  const [expandSearchField, setExpandSearchField] = useState(false)
  const isMd = useMedia('md')
  const router = useRouter()

  const { setHeaderHeight } = useLayoutProperty()
  const { elementRef: headerRef, height: headerHeight } = useElementSize({
    onChangeHeight: setHeaderHeight,
  })
  const { profile } = useAuthState()

  return (
    <>
      <Navbar
        expand="md"
        className={`flex-shrink-0 shadow-sm bg-white d-print-none w-100 ${
          fixed ? 'position-fixed' : 'zindex-fixed'
        }`} // z-index は常に fixed と同じ状態にする
        ref={headerRef}
        style={{ height: 55 }} // ヘッダーの高さは中の要素の高さの影響を受けないように固定値にした
      >
        <div
          className="w-100 d-flex justify-content-between align-items-center gap-md-6 gap-lg-7 px-2 px-md-4 mx-auto"
          style={{ maxWidth }}
        >
          {(!expandSearchField || isMd) && (
            <div className="d-flex gap-4 gap-md-6 gap-lg-7 align-items-center">
              <Link href="/" passHref>
                <Navbar.Brand className="d-flex align-items-center py-0 h-100 gap-2">
                  <Image
                    src="/logo.png"
                    alt="Mathpedia"
                    width={30}
                    height={30}
                  />
                  Mathpedia
                </Navbar.Brand>
              </Link>
              <>
                <Link href={'/about'} passHref>
                  <Nav.Link className="text-muted">理念</Nav.Link>
                </Link>
                <Link href={'/support'} passHref>
                  <Nav.Link className="text-muted">支援</Nav.Link>
                </Link>
                <Link href={'/terms'} passHref>
                  <Nav.Link className="text-muted">辞書</Nav.Link>
                </Link>
              </>
            </div>
          )}
          <div
            className="flex-grow-1 d-flex align-items-center gap-2"
            style={{ maxWidth: 400 }}
          >
            <SearchField
              onSearch={async (keyword) => {
                const pathname = router.pathname
                await router.push(
                  `${
                    ['/glossaries', '/textbooks'].includes(pathname)
                      ? pathname
                      : '/glossaries'
                  }?keyword=${keyword}`
                )
              }}
              variant="light"
              className="flex-grow-1"
              onExpand={() => setExpandSearchField(true)}
              onShrink={() => setExpandSearchField(false)}
            />
            {isMd && <PCHeaderUserMenu />}
          </div>
        </div>
      </Navbar>
      {fixed && (
        <div className="flex-shrink-0" style={{ height: headerHeight }} />
      )}
    </>
  )
}
