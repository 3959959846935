import router from 'next/router'
import { Button } from 'react-bootstrap'
import { FaPencilAlt, FaTrash } from 'react-icons/fa'
import { IconWrapper } from '../../assets/IconWrapper'
import { usePopupMessage } from '../../assets/messenger'
import { Textbook, deleteDoc } from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import { useCurrentUserRoles } from '../../hooks'
import { createTextbookDraft } from '../../models/textbook'
import { Designed } from '../../types'

type DeleteLinkProps = {
  textbook: Textbook
}

const DeleteLink = ({ textbook }: DeleteLinkProps) => {
  const { setPopupMessage } = usePopupMessage()
  return (
    <Button
      variant="link"
      className="text-danger"
      onClick={async () => {
        const confirmed = window.confirm('本当に削除しますか。')
        if (confirmed) {
          await deleteDoc(textbook.ref)
          setPopupMessage('参考書を削除しました。', 'danger')
          await router.push(`/users/${textbook.created_by}`)
        }
      }}
    >
      <IconWrapper suffix="削除">
        <FaTrash />
      </IconWrapper>
    </Button>
  )
}

type EditLinkProps = {
  textbook: Textbook
  onEditButtonClick?: (textbook: Textbook) => Promise<boolean> | boolean
}

const EditLink = ({ textbook }: EditLinkProps) => {
  const { user } = useAuthState()
  return (
    <Button
      variant="link"
      className="text-success"
      onClick={async () => {
        if (!user) throw new Error('This action needs to authenticate.')
        const textbookDraftRef = await createTextbookDraft(
          user.uid,
          textbook.ref
        )
        await router.push(`/textbook_drafts/${textbookDraftRef.id}/edit`)
      }}
    >
      <IconWrapper suffix="編集">
        <FaPencilAlt />
      </IconWrapper>
    </Button>
  )
}

type Props = {
  textbook: Textbook
}

export const TextbookOperationLinks = ({
  textbook,
  className = 'd-flex gap-2',
  style,
}: Designed<Props>) => {
  const { hasRole } = useCurrentUserRoles()
  if (hasRole('mathpedia_admin') !== true) return <></>
  return (
    <div className={className} style={style}>
      <EditLink textbook={textbook} />
      <DeleteLink textbook={textbook} />
    </div>
  )
}
