import Link from 'next/link'
import { ESTextbook } from '../../elastic_search'
import { Textbook } from '../../firebase'
import { Designed } from '../../types'
import { MathJax } from '../mathdown/mathjax'
import { ShapedTimestamp } from '../timestamp/ShapedTimestamp'
import { TextbookImage } from './Image'
import { TextbookTitleLink } from './TitleLink'

type ItemProps = {
  textbook: Textbook | ESTextbook
}

export const TextbookItem = ({
  textbook,
  ...wrapperProps
}: Designed<ItemProps>) => {
  return (
    <a>
      <div {...wrapperProps}>
        <div className="d-flex align-items-top gap-3 gap-md-4">
          <div>
            <TextbookImage textbook={textbook} width={100} />
          </div>
          <div className="flex-grow-1 overflow-hidden">
            <TextbookTitleLink
              textbook={textbook}
              className="text-break text-truncate-1"
            />
            <MathJax className="my-2 text-truncate-3">
              {textbook.description}
            </MathJax>
            <div className="text-muted small">
              <ShapedTimestamp timestamp={textbook.created_at} />
            </div>
          </div>
        </div>
      </div>
    </a>
  )
}

type Props = {
  textbooks: Textbook[] | ESTextbook[]
}

export const TextbookList = ({
  textbooks,
  ...wrapperProps
}: Designed<Props>) => {
  return (
    <div {...wrapperProps}>
      <div className="d-flex flex-column gap-6">
        {textbooks.map((textbook) => (
          <Link key={textbook.id} href={`/textbooks/${textbook.id}`} passHref>
            <TextbookItem textbook={textbook} className="cursor-pointer" />
          </Link>
        ))}
        {textbooks.length === 0 && <>参考書がありません。</>}
      </div>
    </div>
  )
}
