import { signOut } from 'firebase/auth'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { NavDropdown } from 'react-bootstrap'
import {
  FaCog,
  FaListUl,
  FaSignInAlt,
  FaSignOutAlt,
  FaUserCircle,
  FaUserCog,
} from 'react-icons/fa'
import { IconWrapper } from 'web_core/assets/IconWrapper'
import { usePopupMessage } from 'web_core/assets/messenger'
import { UserImage } from 'web_core/assets/user'
import { useAuthState } from 'web_core/firebase/hooks'
import { fb } from 'web_core/firebase/instance'

export const PCHeaderUserMenu = () => {
  const { user, profile } = useAuthState()
  const { t } = useTranslation()
  const { setPopupMessage } = usePopupMessage()

  return (
    <NavDropdown
      title={
        profile ? (
          <UserImage size={35} profile={profile} className="d-inline-block" />
        ) : (
          <FaUserCircle
            style={{ fontSize: 35 }} // UserImageのsizeと一致させた。height, width に1emが設定されるため。
          />
        )
      }
      className="d-none d-md-block"
      align="end"
    >
      {user ? (
        <>
          <Link href="/glossary_drafts" passHref>
            <NavDropdown.Item>
              <IconWrapper
                suffix={t('mathpedia.components.layout.header.dropdown.drafts')}
              >
                <FaListUl />
              </IconWrapper>
            </NavDropdown.Item>
          </Link>
          <Link href="/default_macros" passHref>
            <NavDropdown.Item>
              <IconWrapper
                suffix={t('mathpedia.components.layout.header.dropdown.macros')}
              >
                <FaCog />
              </IconWrapper>
            </NavDropdown.Item>
          </Link>
          <NavDropdown.Divider />
          <Link href="/auth/setting" passHref>
            <NavDropdown.Item>
              <IconWrapper
                suffix={t(
                  'mathpedia.components.layout.header.dropdown.setting'
                )}
              >
                <FaUserCog />
              </IconWrapper>
            </NavDropdown.Item>
          </Link>

          <NavDropdown.Item
            onClick={async () => {
              await signOut(fb.auth)
              setPopupMessage(
                t('packages.firebase.popup_messages.sign_out'),
                'danger'
              )
            }}
          >
            <IconWrapper
              suffix={t('mathpedia.components.layout.header.dropdown.sign_out')}
            >
              <FaSignOutAlt />
            </IconWrapper>
          </NavDropdown.Item>
          {/* <NavDropdown.Divider /> */}
        </>
      ) : (
        <>
          {/* <Link href="/auth/sign_up" passHref>
            <NavDropdown.Item>
              <IconWrapper
                suffix={t(
                  'mathpedia.components.layout.header.dropdown.sign_up'
                )}
              >
                <FaUserPlus />
              </IconWrapper>
            </NavDropdown.Item>
          </Link> */}
          <Link href="/auth/sign_in" passHref>
            <NavDropdown.Item>
              <IconWrapper
                suffix={t(
                  'mathpedia.components.layout.header.dropdown.sign_in'
                )}
              >
                <FaSignInAlt />
              </IconWrapper>
            </NavDropdown.Item>
          </Link>
        </>
      )}
    </NavDropdown>
  )
}
