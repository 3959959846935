import Link from 'next/link'
import { ESTextbook } from '../../elastic_search'
import { Textbook } from '../../firebase'
import { Designed } from '../../types'

type Props = {
  textbook: Textbook | ESTextbook
}

export const TextbookTitleLink = ({
  textbook,
  ...containerProps
}: Designed<Props>) => (
  <Link href={`/textbooks/${textbook.id}`} passHref>
    <a {...containerProps}>{textbook.title}</a>
  </Link>
)
