import { MutableRefObject } from 'react'
import { Mathdown } from '../../assets/mathdown'
import {
  Category,
  Chapter,
  Macro,
  Page,
  Reference,
  Textbook,
} from '../../firebase'
import { Designed } from '../../types'
import { ReferencesList } from '../reference'

type Props = {
  textbook: Textbook
  pages: Page[]
  chapters: Chapter[]
  currentPage: Page
  category: Category
  references: Reference[]
  macros: Macro[]
  contentRef: MutableRefObject<HTMLDivElement | null>
}

export const TextbookPageDetail = ({
  textbook,
  pages,
  chapters,
  currentPage,
  category,
  references,
  macros,
  contentRef,
  ...wrapperProps
}: Designed<Props>) => {
  return (
    <div {...wrapperProps}>
      <div className="mathdown-monotone">
        <h1>{currentPage.title}</h1>
        <div ref={contentRef}>
          <Mathdown
            mathdown={currentPage.body}
            macros={macros}
            references={references}
            className="text-break"
          />
          {references.length > 0 && (
            <>
              <h2>参考文献</h2>
              <ReferencesList references={references} amazonBookImage link />
            </>
          )}
        </div>
      </div>
    </div>
  )
}
